import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared.service';
import { filter, takeUntil } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import * as moment from 'moment';
import * as $ from 'jquery';
declare var $: any;
import { CalendarOptions, DateSelectArg, EventClickArg, EventApi, Calendar } from '@fullcalendar/angular'; // useful for typechecking
import { ActivatedRoute } from '@angular/router';
import Tooltip from 'tooltip.js';

// import interactionPlugin from '@fullcalendar/interaction'; // for selectable

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.css'],
  providers: [MessageService],
  encapsulation: ViewEncapsulation.None
})
export class AvailabilityComponent implements OnInit {

  doctorList: any[] = [];
  events: any[];
  options: any;
  loading: boolean = false;
  selectedStartTime: any;
  selectedEndTime: any;
  multidays: any = [];
  proceedFlag: boolean = false
  Specialty: any = '';

  resources: [
    { id: 'a', title: 'Existing' },
    { id: 'b', title: 'add New' }
  ]
  calendarApi: Calendar;

  calendarOptions: CalendarOptions = {

    initialView: 'dayGridMonth',
    contentHeight: this.getHeight(),
    // expandRows: true,
    selectable: true,
    eventOrder: "sort",
    select: this.handleDateSelect.bind(this),
    dateClick: this.onDateClick.bind(this), // bind is important!
    dayMaxEventRows: true,
    views: {
      timeGrid: {
        dayMaxEventRows: 2// adjust to 6 only for timeGridWeek/timeGridDay
      }
    },
    // eventMouseEnter: this.onEventMouseOver,
    // eventMouseLeave: this.onEventMouseOut,
    eventDidMount: this.onMount,
    events: [],
    longPressDelay: 100,
    eventLongPressDelay: 100,
    selectLongPressDelay: 100,
    // eventClick: this.value.bind(this),
    // eventClick: this.handleEventClick.bind(this),
    // eventsSet: this.handleEvents.bind(this),
    // plugins: [ interactionPlugin ],
    headerToolbar: {
      right: 'add_event,prev,next',
      left: 'title'
    },
    customButtons: {
      add_event: {
        text: 'Unselect',
        click: () => {
          this.clearDates();
        }
      }
    },
    editable: true,
  };
  role: string;
  times: any = [];
  duration = '15';
  startDate: any;
  endDate: any;
  amount: number = 0;
  currency: any = "USD";
  user_id: any;
  available_dates: any[];
  available_slots: any[];
  date: any[];
  slots: any = [];
  listOfSlots: any = [{
    from_time: '',
    to_time: ''
  }];
  // selectedDoctor: any;
  doctorName: string;
  arr: any[];
  listOfAvailabilities: any = [];
  availability_id: number = 0;
  day: any;
  durationInMinutes: number;
  showDuration: boolean = false;
  showMsg: boolean;
  checked: boolean = false;

  //datepicker starts
  // selected: any;
  // ranges: any = {
  //   'Today': [moment(), moment()],
  //   'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
  //   'Last 7 Days': [moment().subtract(6, 'days'), moment()],
  //   'Last 30 Days': [moment().subtract(29, 'days'), moment()],
  //   'This Month': [moment().startOf('month'), moment().endOf('month')],
  //   'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
  // }
  // invalidDates: moment.Moment[] = [];
  // placeholder = "";
  // alwaysShowCalendars: boolean;

  //datepicker ends

  constructor(public sharedService: SharedService,
    private messageService: MessageService, private activateRoute: ActivatedRoute
  ) {
    const current = new Date();
    // this.alwaysShowCalendars = true;
  }

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((params) => {
      if (params.availability === 'yes') {
        this.sharedService.homeFlag = true;
      } else if (!params.availability) {
        this.sharedService.homeFlag = false;
      }
      if (params.doctorID) {
        this.sharedService.selectedDoctor = params.doctorID;
      }
      if (params.day) {
        this.day = params.day;
      }
    });
    this.getDoctorList();
    this.role = localStorage.getItem('role');
    this.user_id = localStorage.getItem('user_id');
    this.getListOfTimes(this.duration);
    const date = new Date();
    const newDate = new Date();
    const dateAfterThreeMonths = date.setMonth(date.getMonth() + 3);

    if (this.role === 'doctor') {
      let data = {};
      data['dates'] = [];
      let date1;
      if (this.day) {
        date1 = parseInt(this.day);
      } else {
        date1 = this.sharedService.convertToUnixTimeStamp(newDate, "00:00")
      }
      data['dates'].push(date1);
      this.getAvailability(newDate, dateAfterThreeMonths);
      this.getAvailabilityforDates(data);
    }
    else if (this.sharedService.homeFlag === true) {
      let data = {};
      data['dates'] = [];
      let date1;
      if (this.day) {
        date1 = parseInt(this.day);
      } else {
        date1 = this.sharedService.convertToUnixTimeStamp(newDate, "00:00");
      }
      data['dates'].push(date1);
      this.getAvailability(newDate, dateAfterThreeMonths);
      this.getAvailabilityforDates(data);
      // setTimeout(() => {
      //   this.getSelectedDoctor(this.sharedService.selectedDoctor);
      // },1000)
    }

  }

  getListOfTimes(duration) {
    this.times = [];
    const start_date = this.sharedService.convertToUnixTimeStamp(new Date(), '00:00');
    const end_date = this.sharedService.convertToUnixTimeStamp(new Date(), '23:59');
    this.recursiveTimeSlots(start_date, duration, end_date);
  }

  // value(event, element) {
  //   // console.log(event, element)
  //   event.popover({ title: event.title, content: event.description, trigger: 'hover', })
  //   // $(element).popover({title: event.title, content: event.description, trigger: 'hover', placement: 'auto right', delay: {"hide": 300 }});

  // }

  recursiveTimeSlots(start_time, call_duration, end_time) {
    call_duration = parseInt(call_duration);
    let obj = {};
    obj['label'] = moment(start_time).format('LT');
    let dt = new Date(start_time);
    let min = dt.getMinutes() + call_duration;
    let dt2 = new Date(dt.setMinutes(min));
    obj['value'] = moment(start_time).format('LT');
    this.times.push(obj);
    let dt3 = new Date(end_time);
    let min1 = dt3.getMinutes() - call_duration +1 ; // end time is 1-minute before end of hour
    let dt4 = new Date(dt3.setMinutes(min1));
    if (dt2 <= dt4) {
      this.recursiveTimeSlots(dt2, call_duration, end_time);
    }
  }

  handleClick(value, other?) {
    if (value === 'other') {
      this.showDuration = true;
    } else {
      this.showDuration = false;
      this.duration = value;
      this.getListOfTimes(+this.duration);
    }
  }

  getMinutes(value) {
    this.duration = 'other';
    if (value < 15 || value > 200) {
      this.showMsg = true;
    } else {
      this.showMsg = false;
      this.getListOfTimes(value);
    }

  }

  getAvailabilityforDates(data) {
    let id = 0;
    if (this.role === 'doctor') {
      id = this.user_id;
    } else if (this.role === 'admin' || this.role === 'healthstaff') {
      id = this.sharedService.selectedDoctor;
    }
    this.loading = true;
    this.listOfAvailabilities = [];
    this.sharedService.getAvailabilityforDates(id, data).subscribe((res) => {
      // console.log(res,"res from dates availability");
      this.loading = false;
      res['data'].forEach(ele => {
        let obj = {};
        obj['start_time'] = this.sharedService.convertToStandardTime(ele['start_time']).split(',')[1];
        obj['end_time'] = this.sharedService.convertToStandardTime(ele['end_time']).split(',')[1];
        obj['consultation_fees'] = ele['amount'];
        obj['visit_duration'] = ele['call_duration'];
        obj['availability_id'] = ele['availability_id'];
        obj['date'] = this.sharedService.convertToStandardTime(ele['start_time']).split(',')[0];
        this.listOfAvailabilities.push(obj);
      });

    }, (err) => {
      this.loading = false;
      this.messageService.clear();
      // this.messageService.add({ severity: 'warn', summary: 'Error', detail: err['error']['message'] });
    });

  }

  arrVal = [];
  onDateClick(clickedDate: any) {
    const index = this.arrVal.findIndex(val => val.start === clickedDate.dateStr && val.resourceId === 'a');
    if (index === -1) {
      const obj = {
        start: clickedDate.dateStr,
        end: clickedDate.dateStr,
        display: 'background',
        eventBackgroundColor: 'yellow',
        backgroundColor: 'blue',
        resourceId: 'a'
      }
      this.arrVal.push(obj);
      this.calendarOptions.events = [...this.arrVal];
    } else {
      // if()
      this.arrVal.splice(index, 1);
      this.calendarOptions.events = [...this.arrVal];
    }
  }

  handleDateSelect(selectInfo: DateSelectArg) {
    // console.log(selectInfo,"11111111");

    this.startDate = selectInfo.startStr;
    this.endDate = selectInfo.endStr;
    setTimeout(() => {
      this.viewAvailability();
    }, 100)
  }

  viewAvailability() {
    let data = {};
    data['dates'] = [];
    this.arrVal.forEach(ele => {
      if (ele['resourceId'] === 'a') {
        let startDate = this.sharedService.convertToUnixTimeStamp(ele['start'], '00:00');
        data['dates'].push(startDate);
      }

    })
    // console.log(data['dates'],data['dates'].length,"length");

    if (data['dates'].length > 0) {
      this.getAvailabilityforDates(data);
    } else if (data['dates'].length === 0) {
      let obj = {};
      obj['dates'] = [];
      for (var m = moment(this.startDate); m.isBefore(this.endDate); m.add(1, 'days')) {
        let x = m.format('L');
        let y = this.sharedService.convertToUnixTimeStamp(x, "00:00");
        obj['dates'].push(y);
      }
      this.getAvailabilityforDates(obj);
    }
  }


  setAvailability() {
    let t1 = moment(this.selectedStartTime, "HH:mm a");
    let t2 = moment(this.selectedEndTime, "HH:mm a");
    if (t1 >= t2) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'End time should be greater than Start time' });
      return;
    }

    ////////////days
    this.date = [];
    const new_events_list = this.arrVal.filter(val => val.resourceId === 'a');
    if (this.startDate && this.endDate && !new_events_list.length) {
      for (var m = moment(this.startDate); m.isBefore(this.endDate); m.add(1, 'days')) {
        this.date.push(m.format('L'));
      } // get all dates between startDate and endDate
    } else if (new_events_list.length) {
      new_events_list.forEach((val) => {
        var m = moment(val.start)
        this.date.push(m.format('L'));
      })
    }
    // return;


    let timeArr = [];
    //iterate each date
    this.date.forEach(date => {

      //iterate time selected for each date
      let obj1 = [];
      // this.listOfSlots.forEach(time => {

      const t1 = moment(this.selectedStartTime, ["h:mm A"]).format("HH:mm");
      const t2 = moment(this.selectedEndTime, ["h:mm A"]).format("HH:mm");
      let timeData = {
        start_time: this.sharedService.convertToUnixTimeStamp(date, t1),
        end_time: this.sharedService.convertToUnixTimeStamp(date, t2)
      }
      obj1.push(timeData);

      // });
      const dateData = {
        available_slots: [...obj1],
        call_duration: this.duration,
        amount: this.amount,
        currency: "USD",
        date: this.sharedService.convertToUnixTimeStamp(date, '00:00')
      }
      timeArr.push(dateData);
    });
    const data = {
      available_dates: [...timeArr]
    }
    let id = 0;
    if (this.role === 'doctor') {
      id = this.user_id;
    } else if (this.role === 'admin' || this.role === 'healthstaff') {
      id = this.sharedService.selectedDoctor;
    }
    const n1 = new Date();
    const n2 = new Date();
    const n3 = n1.setMonth(n1.getMonth() + 3)
    let setFlag1 = false;
    let setFlag2 = true;
    let ts = this.sharedService.convertToUnixTimeStamp(n2, "00:00")
    data['available_dates'].forEach(ele => {
      if (ele['date'] < ts) {
        setFlag1 = true;
      }
      if (ele['date'] > n3) {
        setFlag2 = false;
      }
    })
    if (setFlag1) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'Availability cannot be set for past dates' });
      return;
    } else if (!setFlag2) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'Availability can be set only for three months' });
      return;
    } else if (this.date.length === 0) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'Please select date(s) to set availability' });
      return;
    } else if (!setFlag1 && setFlag2) {
      this.loading = true;
      this.sharedService.setAvailability(id, data).subscribe((res) => {
        this.loading = false;
        this.messageService.clear();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: res['message'] });
        const date = new Date();
        const newDate = new Date();
        const dateAfterThreeMonths = date.setMonth(date.getMonth() + 3);
        this.getAvailability(newDate, dateAfterThreeMonths);
        this.viewAvailability();//view availability tab
        this.initialise();

      }, (err) => {
        this.loading = false;
        this.messageService.clear();
        this.messageService.add({ severity: 'warn', summary: 'Error', detail: err['error']['message'] });
      });
    }
  }


  getAmount(amount) {
    this.amount = amount;
  }

  getAvailability(d1, d2,) {
    this.loading = false;
    this.listOfAvailabilities = [];
    const start_date = this.sharedService.convertToUnixTimeStamp(d1, '00:00');
    const end_date = this.sharedService.convertToUnixTimeStamp(d2, '23:59');
    let id = 0;
    if (this.role === 'doctor') {
      id = this.user_id;
    } else if (this.role === 'admin' || this.role === 'healthstaff') {
      id = this.sharedService.selectedDoctor;
    }
    this.sharedService.getAvailability(id, start_date, end_date).subscribe((res) => {
      this.loading = false;
      // console.log(res,"res from get availability")
      let arr = []
      res['data'].forEach((val) => {
        const obj = {
          resourceId: 'b',
          title: moment(val.start_time).format('LT') + "-" + moment(val.end_time).format('LT'),
          start: moment(val.start_time).format('YYYY-MM-DD'),
        }
        arr.push(obj);

      })
      this.arrVal = [...arr];
      this.calendarOptions.events = [...arr];


    }, (err) => {
      this.loading = false;
    });
  }

  // addNewAvailability(slot) {
  //   let obj = {};
  //   obj['from_time'] = slot.from_time;
  //   obj['to_time'] = slot.to_time;
  //   this.listOfSlots.push(obj);
  // }

  getDoctorList() {
    this.loading = true;
    this.sharedService.getListOfDoctorOnStatus('active').subscribe((result) => {
      this.loading = false;
      if (result['response']['success'] === true) {
        result['response']['data'].forEach(doc => {
          const obj = {
            label: doc.first_name + ' ' + doc.last_name,
            value: doc.user_id,
            specialty: doc.doctor_specialty
          }
          this.doctorList.push(obj);
        });
      }
    }),
      (err) => {
        this.loading = false;
      }
    setTimeout(() => {
      this.getSelectedDoctor(this.sharedService.selectedDoctor);
    }, 1000)

  }

  proceed() {
    if (!this.sharedService.selectedDoctor) {
      this.proceedFlag = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'Please select clinician to proceed' });
    } else if (this.sharedService.selectedDoctor) {
      this.proceedFlag = true;
      const d1 = new Date();
      const d2 = new Date();
      const d3 = d1.setMonth(d1.getMonth() + 3);
      this.getAvailability(d2, d3);
      let data = {};
      data['dates'] = [];
      let date = this.sharedService.convertToUnixTimeStamp(d2, "00:00")
      data['dates'].push(date);
      this.getAvailabilityforDates(data);
    }

  }

  getSelectedDoctor(value) {
    this.doctorList.forEach(ele => {
      if (ele.value === value) {
        this.doctorName = ele.label;
        this.Specialty = ele.specialty
      }
    });
    // this.sharedService.selectedDoctor = '';
  }

  deleteSlots() {
    let id = 0;
    if (this.role === 'doctor') {
      id = this.user_id;
    } else if (this.role === 'admin' || this.role === 'healthstaff') {
      id = this.sharedService.selectedDoctor;
    }
    let start_date = this.sharedService.convertToUnixTimeStamp(this.startDate, '00:00');
    let end_date = this.sharedService.convertToUnixTimeStamp(this.startDate, '23:59');
    this.arr = [];
    if (this.startDate) {
      this.sharedService.getAvailability(id, start_date, end_date).subscribe((res) => {
        res['data'].forEach(ele => {
          this.arr.push(ele['availability_id']);
        });

      },
        (err) => {

        })
      setTimeout(() => {
        this.resetSlots(id);
      }, 2000)

    } else if (!this.startDate) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: ' Please select a date inorder to reset' });
    }
  }

  resetSlots(id) {
    let payload = {};
    payload['data'] = [];
    payload['data'] = this.arr;
    if (payload['data'].length > 0) {
      this.loading = true;
      this.sharedService.deleteSlots(id, payload).subscribe((res) => {
        this.loading = false;
        this.messageService.clear();
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Time slot deleted' });
      }, (err) => {
        this.loading = false;
        this.messageService.clear();
        this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });
      });
    } else if (payload['data'].length === 0) {
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'There are no slots booked' });
    }

  }

  navSelectClinician() {
    this.proceedFlag = false;
    this.sharedService.homeFlag = false;
    this.sharedService.selectedDoctor = '';
  }

  showDeletePopUp(slot) {
    this.availability_id = slot.availability_id;
    $('#deleteSlotPopUp').modal('show');
  }

  delete() {
    let payload = {};
    payload['data'] = [];
    payload['data'].push(this.availability_id);
    let id = 0;
    if (this.role === 'doctor') {
      id = this.user_id;
    } else if (this.role === 'admin' || this.role === 'healthstaff') {
      id = this.sharedService.selectedDoctor;
    }
    // if(payload['data'].length > 0){
    this.loading = true;
    this.sharedService.deleteSlots(id, payload).subscribe((res) => {
      this.loading = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Time slot deleted' });
      this.viewAvailability();
      const d1 = new Date();
      const d2 = new Date();
      const dateAfterThreeMonths = d1.setMonth(d1.getMonth() + 3);
      this.getAvailability(d2, dateAfterThreeMonths);

    }, (err) => {
      this.loading = false;
      this.messageService.clear();
      this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });
    });
  }

  clearDates() {
    const filteredPeople = this.arrVal.filter((item) => item['resourceId'] !== 'a');
    this.arrVal = filteredPeople;
    if (filteredPeople.length > 0) {
      this.calendarOptions.events = [...this.arrVal];
    }
  }

  getHeight() {
    return 600
  }

  handleChange(value) {
    if (value === 1) {
      this.initialise();
    }
  }

  initialise() {
    this.selectedStartTime = '';
    this.selectedEndTime = '';
    this.getAmount(0);
    this.showDuration = false;
  }

  isNumber(value) {
    let keyCode = value.keyCode
    if (keyCode > 47 && keyCode < 58) {
      return true;
    }
    return false;
  }

  // onEventRender(info: any) {
  //   const tooltip = new Tooltip(info.el, {
  //     title: info.event.title,
  //     placement: 'top-end',
  //     trigger: 'hover',
  //     container: 'body'
  //   });
  //   console.log(tooltip)
  // }

  // onEventMouseOver(calEvent) {
  //   var tooltip = '<div class="tooltipevent" style="width:100px;height:100px;background:#ccc;position:absolute;z-index:10001;">' + calEvent.event._def.title + '</div>';
  //   var $tooltip = $(tooltip).appendTo('.fc-event-title');
  //   $(this).mouseover(function (e) {
  //     $(this).css('z-index', 10000);
  //     $tooltip.fadeIn('500');
  //     $tooltip.fadeTo('10', 1.9);
  //   }).mousemove(function (e) {
  //     $tooltip.css('top', e.pageY + 10);
  //     $tooltip.css('left', e.pageX + 20);
  //   });
  //   return tooltip;
  // }

  // onEventMouseOut() {
  //   console.log("hi");
  //   $(this).css('z-index', 8);
  //   $('.tooltipevent').remove();
  //   // return t1;
  // }

  onMount(info) {
    return info.el.title = info.event._def.title;
  }



}
