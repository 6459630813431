import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../shared.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-questionaire',
  templateUrl: './questionaire.component.html',
  styleUrls: ['./questionaire.component.sass'],
  providers: [MessageService]
})
export class QuestionaireComponent implements OnInit {
  consultationType = "first_time";
  patient_questionaries: any = '';
  patient_mrn: any = '';
  patient_name: any = '';
  subscription$: Subject<boolean> = new Subject<boolean>();
  doctorList: any = [];
  doctorName: any = '';
  doctorID: any;
  patient_complaint: string = '';
  patient_medication: string = '';
  patient_allergies: string = '';
  patient_preexisting_conditions: string = '';
  selectedPatient: any = '';
  attachmentFile: File = null;
  imageUrl: string = "";


  @Input() role = '';
  @Output() activePage: EventEmitter<any> = new EventEmitter;
  patientList: any = [];
  clientList: any = [];
  selectedClient: any;
  noUser: boolean = false;
  name: string;
  doctorBio: any;
  doctorProfileImg: any;
  bookAppointment: boolean = false;;
  // files: string[] = [];

  constructor(private router: Router,
    private active: ActivatedRoute,
    public ss: SharedService,
    private messageService: MessageService, private http: HttpClient) { }

  ngOnInit(): void {
    window.scroll(0, 0);
    this.getListOfDoctors();
    this.getListOfPatients();
    setTimeout(() => {      
      this.active.queryParams.subscribe((params) => {
        if(params.bookAppointment){
          this.bookAppointment = true;
        }else if(!params.bookAppointment){
          this.bookAppointment = false;
        }
        if (localStorage.getItem('userDetails') && this.bookAppointment) {          
          const details = JSON.parse(localStorage.getItem('userDetails'));          
          this.patient_complaint = details.patient_complaint;
          this.patient_allergies = details.patient_allergies;
          this.patient_medication = details.patient_medication;
          this.patient_preexisting_conditions = details.patient_preexisting_conditions;
          this.patient_name = details.patient_name;
          this.patient_mrn = details.patient_mrn;
          this.consultationType = details.consultationType ? details.consultationType : 'first_time';    
          setTimeout(() => {
            this.setDoctor(details.doctorID);
            this.getDoctorName(details.doctorID);
          }, 1000)
        }
      })      
      this.active.params.subscribe((data) => {
        if (data.id) {
          sessionStorage.setItem("horn_client_name", data.client);
          const client = this.clientList.find(ele => ele.value.id === data.id)
          // this.selectedClient = data.id;
          if (client) {
            this.selectedClient = client.value;
          }
        }
      })
    }, 1000);
    // if (localStorage.getItem('userDetails') && this.bookAppointment) {
    //   console.log("hi");
      
    //   const details = JSON.parse(localStorage.getItem('userDetails'));
    //   console.log(details,"details");
      
    //   this.allergy = details.allergies;
    //   this.medication = details.medication;
    //   this.condition = details.pre_existing_conditions;
    //   this.patient_questionaries = details.patient_questionaries ? details.patient_questionaries : '';
    //   this.consultationType = details.consultationType ? details.consultationType : 'first_time';
    //   // this.doctorID =  details.doctorID;

    //   setTimeout(() => {
    //     this.setDoctor(details.doctorID);
    //     this.setPatient(details.patientName);
    //     this.getDoctorName(details.doctorID);
    //     // this.selectedPatient = details.patientID ? details.patientID : 0;
    //     // this.selectedPatient.name = details.patientName ?  details.patientName : '';
    //   }, 1000)
    // }

    if (this.router.url.includes('client')) {
      const role = localStorage.getItem('role');
      setTimeout(() => {
        if (role === 'doctor') {
          this.setDoctor(parseInt(localStorage.getItem('user_id')))
        }
        if (role === 'patient') {
          const patientID = parseInt(localStorage.getItem('user_id'));
          this.selectedPatient = this.patientList.find(ele => ele.value === patientID)
        }
      }, 2000);
    }
  }

  setDoctor(id) {
    const stateValue = this.doctorList.find(ele => ele.value.user_id === id);
    this.doctorID = stateValue ? stateValue.value : 0;
  }

  selectConsultation(type) {
    this.consultationType = type;
  }

  submit() {
    this.uploadAttachments();
    let obj = {
      patient_name: this.patient_name,
      patient_mrn: this.patient_mrn,
      doctorName: this.doctorName ? this.doctorName : '',
      doctorID: this.doctorID ? this.doctorID['user_id'] : '',
      consultationType: this.consultationType,
      patient_complaint: this.patient_complaint,
      patient_allergies: this.patient_allergies,
      patient_medication: this.patient_medication,
      patient_preexisting_conditions: this.patient_preexisting_conditions,
    }
    localStorage.setItem('userDetails', JSON.stringify(obj));
    this.activePage.emit(obj);
  }

  cancel() {
    this.router.navigate([`${this.role}/dashboard`]);
  }

  getListOfDoctors() {
    this.ss.getActiveDoctors().pipe(takeUntil(this.subscription$)).subscribe(
      res => {
        res['response']['data'].forEach(ele => {
          let obj = {};
          obj['label'] = 'Dr. ' + ele['first_name'] + ' ' + ele['last_name'];
          obj['value'] = ele;
          this.doctorList.push(obj);
        });
      },
      err => {
        this.messageService.clear();
        // this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });
      }
    );
  }

  clearPatientName(event) {
    this.selectedPatient = '';
  }

  getClients() {
    this.ss.getClients().subscribe((data) => {
      if (data['success'] === true) {
        data['data']['results'].forEach(element => {
          const obj = {
            label: element.name,
            value: element
          }
          this.clientList.push(obj);
        });
      }
    });
  }

  ViewMdNav() {
    this.router.navigate([`/${this.role}/appointment`], { queryParams: { page: 'questionaire' } })
  }

  getClientUrl(event) {
    // if(value === null) {
    // this.router.navigate([`/${this.role}/appointment`], { queryParams: {  page: 'questionaire' } } )
    // } else {
    // }
    this.router.navigate(['/client/appointment', this.selectedClient.name, this.selectedClient.id], { queryParams: { page: 'questionaire' } })
  }


  getListOfPatients() {
    this.ss.getActivePatients().pipe(takeUntil(this.subscription$)).subscribe(
      res => {
        this.patientList = [];
        if (res['response']['data'] && res['response']['data'].length > 0) {
          res['response']['data'].forEach(ele => {
            ele['name'] = ele['first_name'] + " " + ele['last_name'];
            var Obj = { 'name': ele['name'], 'value': ele['user_id'] };
            this.patientList.push(Obj);
          });
        }
      }, err => {
        this.patientList = [];
      });
  }

  filterPatient(value) {
    this.ss.searchPatient(value).pipe(takeUntil(this.subscription$)).subscribe(
      res => {
        this.patientList = [];
        if (res['response']['data'] && res['response']['data'].length > 0 && (res['response']['success'] === true)) {
          this.noUser = false;
          res['response']['data'].forEach(ele => {
            ele['name'] = ele['first_name'] + " " + ele['last_name'];
            var Obj = { 'name': ele['name'], 'value': ele['user_id'] };
            this.patientList.push(Obj);
          });
        } else if (res['response']['data'].length === 0) {
          this.noUser = true;
          this.messageService.clear();
          this.messageService.add({ severity: 'warn', summary: 'Error', detail: 'User doesnt exist' });
        }
      }, err => {
        this.patientList = [];
        this.noUser = true;
        this.messageService.clear();
        this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.response.message });
      });
  }

  selectPatient(event) {
    this.selectedPatient = event;
  }

  getDoctorName(id) {
    // console.log(this.doctorList,"list");
    
    this.doctorList.forEach(ele => {
      if (ele['value'].user_id === id) {
        this.doctorName = ele['label'];
        this.doctorBio = ele['value']['doctor_bio'];
        this.doctorProfileImg = ele['value']['image_url'];
      }
    });
  }

  handleFileInput(event) {
    for (var i = 0; i < event.target.files.length; i++) {
      this.ss.files.push(event.target.files[i]);
    }    
  }
  uploadAttachments(){
    const formData = new FormData();
    for (var i = 0; i < this.ss.files.length; i++) {
      formData.append("files", this.ss.files[i]);
    }
    this.http.post('https://bmc-la.televiewmd.com:8080/api/uploadmultiplefiles', formData).subscribe(res => {
      // console.log(res);
      // this.ss.attachmentImg
      res['uploadedFile'].forEach(ele => {
        this.ss.attachmentImgs.push(ele['path']);
      });
    },
      (err) => {
        this.messageService.clear();
        this.messageService.add({ severity: 'warn', summary: 'Error', detail: err.error.message });

      })
  }

  onRemove(file) {
    this.ss.files = this.ss.files.filter((item) => item !== file);
  }


}
